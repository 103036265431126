import { images } from '.';

const team = [  
    {
        id: 1,
        image: images.team1,
        name: '2017',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [

        ],
    }, 
     {
        id: 2,
        image: images.team2,
        name: '2018',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 3,
        image: images.team3,
        name: '2019',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 4,
        image: images.team4,
        name: '2020',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 5,
        image: images.team5,
        name: '2021',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 6,
        image: images.team6,
        name: '2022',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 7,
        image: images.team6,
        name: '2023',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 8,
        image: images.team6,
        name: '2024',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
    {
        id: 9,
        image: images.team6,
        name: '2025',
        title: 'VivoCable',
        biography: 'Reportes trimestrales sobre comentarios, sugerencias, aclaraciones o demandas cuando considere vulnerado un derecho como audiencia.',
        accounts: {
        },
        skills: [
            
        ],
    }, 
];

export default team;