import team from './team';
import services from './services';
import projects from './projects';
import products from './products';
import blogs from './blogs'; 



const navbar = [  
    {
        id: 1,
        mainLink : {link: '/', title: 'Inicio'},
        subLink: [],
    },
    {
        id: 2,
        mainLink : {link: '/Cobertura', title: 'Cobertura'},
        subLink: [],
    },
    {
        id: 3,
        mainLink : {link: '/Projects', title: 'Servicios y Tarifas'},
        subLink: [],
    },
    {
        id: 4, 
        mainLink : {link: '/Services', title: 'Tramites'},
        subLink: [],
    },  
    {
        id: 5,
        mainLink : {link: '/Contact-us', title: 'contacto'},
        subLink: [],
    },
    {
        id: 6,
        mainLink : {link: '/legal', title: 'Legal'},
        subLink: [],
    },
];

export default navbar;