import React from 'react';
import './Team.css';

import { Heading, TeamItem, Button } from '../../../components';

import {  FaAngleDoubleRight } from 'react-icons/fa';
import { team } from '../../../constants';

 
 
const Team = () => { 
 
  return (   
    <section className='team'>

      {/* Section heading */}
      <Heading title={'Calendario Trimestral'} subTitle={'VivoCable'} />

  
      

      <div className='box-container'>

        {
          // Map over the team array to render each team Memebr
          team.map((item) => {
            return (
              <TeamItem 
                key={item.id}
                image={item.image}
                name={item.name}
                title={item.title}
                accounts={item.accounts}
                team={item}
              /> 
            )
          })
        }
      </div>

       
                   
    </section>
  )
  
}

export default Team;