import React from 'react';
import './Footer.css';

import { Logo, Heading } from '../../components';
import { contactContent, services } from '../../constants';

import { FaFacebookF, FaAngleDoubleRight, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';


  
const Footer = () => {   
    
  return (
    <footer className='footer'>
      
      <div className='box-container'>

        {/* About Section */}
        <div className='footer-item'> 
          <Logo />
          <p>Conectamos experiencias digitales de calidad. Su visión, nuestra prioridad. Vivocable: su conexión al mundo digital.</p>
          <div className='social'>
            <a href="https://www.facebook.com/vivocabletv" target='_blank'><FaFacebookF className='icon' /></a>
            <a href="https://www.instagram.com/vivo_cable_tv/" target='_blank'><FaInstagram className='icon' /></a>
            <a href="https://api.whatsapp.com/send?phone=5529889577" target='_blank'><FaWhatsapp className='icon' /></a>
          </div>
        </div> 

        <div className='footer-item'> 

          <Heading title={'LEGAL'} />

          <div className='info links'> 
              <p><FaAngleDoubleRight className='icon' /><a href='/Legal/CentroAtencion.pdf' target='_blank' rel='noopener noreferrer'>Centros de Atención</a></p>
              

              
              <p><FaAngleDoubleRight className='icon' /><a href='/Legal/LineamientosGenerales.pdf' target='_blank' rel='noopener noreferrer'>Lineamientos Generales para la Publicación de Información</a></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/about-us'>Defensa de las Audiencias</Link></p>
              <p><FaAngleDoubleRight className='icon' /><a href='/Legal/LaNom.pdf' target='_blank' rel='noopener noreferrer'>NOM-184-SCFI-2018</a></p>
              <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FC%C3%B3digo%20de%20Gesti%C3%B3n%20de%20Tr%C3%A1fico%20y%20Administraci%C3%B3n%20de%20Red.pdf?alt=media&token=408b441b-8aea-448b-bd2f-2654a010bdb2' target='_blank' rel='noopener noreferrer'>Código de Política de Tráfico y Administración de la Red</a></p>
          </div>

        </div>

        {/* Useful Links Section */}
        <div className='footer-item'> 

          <Heading title={'AVISOS'} />

          <div className='info links'> 
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FAviso%20mapa%20de%20cobertura.pdf?alt=media&token=904279a3-4bdc-418c-aba7-05bea0dbc8fb' target='_blank' rel='noopener noreferrer'>Aviso de Mapa de Cobertura</a></p>
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FSistema%20de%20gesti%C3%B3n%20a%20Usuarios%20Finales.pdf?alt=media&token=8d14af8d-9af6-4e32-885b-885da585a241' target='_blank' rel='noopener noreferrer'>Sistema de Gestión a Usuarios Finales</a></p>
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FGASTOS%20RELACIONADOS%20CON%20LA%20TERMINACI%C3%93N%20DEL%20CONTRATO%20DE%20ADHESI%C3%93N.pdf?alt=media&token=c7f80076-4293-4b3e-8f56-28e650f3fe69' target='_blank' rel='noopener noreferrer'>Gastos Relacionados con la Terminación del Contrato</a></p>
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FCALIDAD%20DE%20LOS%20SERVICIOS..pdf?alt=media&token=9cc3be39-8372-4b1b-9ee1-dc4487e4ff61' target='_blank' rel='noopener noreferrer'>Calidad de Servicios</a></p>
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FPOL%C3%8DTICA%20DE%20USO%20DEL%20SERVICIO%20DE%20INTERNET%20FIJO%20EN%20CASA.pdf?alt=media&token=aa5a5137-a12a-45d8-85f5-e63287a7108c' target='_blank' rel='noopener noreferrer'>Política Uso de Internet</a></p>
          <p><FaAngleDoubleRight className='icon' /><a href='https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FVelocidad%20de%20Transferencia.pdf?alt=media&token=c1adc91a-f03a-4891-96ef-f71dc83c99f0' target='_blank' rel='noopener noreferrer'>Velocidad de Transferencia</a></p>
          </div>

        </div> 
  
        {/* Contact Info Section */}
        <div className='footer-item'>  

          <Heading title={'CONTACTO'} />

          <div className='info connect'> 
            {contactContent.map((contact) => {
              return (contact.content).map((value, index) => {
                return <p key={index}><span className='icon'>{contact.icon}</span>{value}</p>
              })
            })}
          </div>
        </div>
      </div>
 
      <div className='content'>
        <p>Diseñada por <a href="https://im-technology.com.mx/"><span>I&M Technology</span></a> | Todos los derechos reservados.</p>
      </div>
      
    </footer>
  )
}

export default Footer;